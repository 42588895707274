import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const Batterycycling = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Lithium-Ion Battery Recycling Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The Lithium-Ion Battery Recycling Prize focuses on identifying
                innovative solutions for collecting, sorting, storing, and
                transporting spent and discarded lithium-ion batteries — from
                electric vehicle (EV), consumer electronics, industrial, and
                stationary applications — for eventual recycling and materials
                recovery.
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The Battery Recycling Prize is a $5.5-million phased prize
                competition designed to incentivize American entrepreneurs to
                develop and demonstrate processes that, when scaled, have the
                potential to profitably capture 90% of all discarded or spent
                lithium-based batteries in the United States for eventual
                recovery of key materials for re-introduction into the U.S.
                supply chain.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/BatteryRecyclingPrize">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
              <span style={{ padding: "5px" }}></span>
              <p style={{ textAlign: "justify" }}>
                This Battery Recycling Prize is NOT for lead-acid batteries as
                currently a vast recycling supply chain collects, stores,
                transports, recycles, and re-introduces more than 99% of lead
                back into the lead-acid battery supply chain.
              </p>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">The Battery Recycling Prize Contests</h1>
          <p className="pcol-fz">
            The competition consists of three phases that will fast-track
            efforts to identify, develop, and test disruptive solutions to meet
            battery recycling needs. Each phase includes a contest period when
            participants work to rapidly advance their solutions.{" "}
          </p>
          <p className="pcol-fz">
            Phase I of the competition is complete, with 15 winners receiving
            $67,000 each, for a total of $1 million awarded. These 15
            competitors have advanced to{" "}
            <a
              href="../../battery-recycling-prize-rules-and-scoring-criteria.pdf"
              target="_blank"
            >
              Phase II
            </a>
            , where they will translate the Phase I concepts into end-to-end
            solutions that demonstrate a viable business model that can be
            scaled. View the Phase II rules to learn more. The rules for Phase
            III will be available at a later date.
          </p>
          <div className="row rww">
            <div class="col" style={{ textAlign: "justify" }}>
              <p>
                <b>Phase I: Concept Development and Incubation (COMPLETE) — </b>
                Applicants submitted a business model or an innovative solution
                and technology plan toward profitable collection, sorting and
                separation, storing and transporting (while rendering batteries
                safe or inert) of end-of-use and spent lithium-ion batteries.
                Applicants submitted concepts related to a single track,
                multiple tracks, or full end-to-end solutions for one or
                multiple applications (consumer electronics, electric vehicles,
                and stationary storage and/or other large industrial uses). Each
                individual/team/entity could apply to more than one track but
                could only enter one submission per track. Up to 15 winners were
                selected at the end of Phase I, with each team receiving
                $67,000.
              </p>
              <p>
                <b>Phase II: Prototyping and Partnering (COMPLETE) — </b>
                Participants design, simulate, and prototype a proof-of-concept
                solution for one or multiple commercial uses (consumer
                electronics, electric vehicles, and stationary storage and/or
                other large industrial uses). Participants are encouraged to
                incorporate winning ideas from various tracks. Up to 10 winners
                will be selected at the end of Phase II to receive cash awards
                (at least $250,000) and non-cash vouchers of $100,000 for use at
                national labs and approved organizations that are within the
                American-Made Network and to enter Phase III.
              </p>
              <p>
                <b>Phase III: Pilot Validation —</b>
                Winning participants from Phase II must substantially advance
                their end-to-end solutions from proof-of-prototype to a refined
                pilot of the technology. This could include building,
                demonstration, and analysis to validate a small-scale pilot
                prototype with a focus on solutions under real-world
                applications and scenarios for one or multiple applications
                (consumer electronics, electric vehicles, and stationary storage
                and/or other large industrial uses). At the end of this phase,
                up to four winners will be selected to receive cash awards (at
                least $500,000).
              </p>
            </div>
            <div class="col">
              <StaticImage
                src="../../images/funnel.webp"
                className="img-styl"
                alt="funnel"
                loading="lazy"
              />
            </div>
          </div>
          <span style={{ padding: "5px" }}></span>
          <p>
            In each phase, submissions will be evaluated by expert reviewers
            (Advisory Reviewers) and a federal consensus panel. The selection
            official will make the final determination based on those reviews.
          </p>
        </div>
        <div className="container">
          <h1 className="mac_h1">Phase II Prototype and Partnering Winners</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to the 7 winners of Phase II of the Lithium-Ion
            Battery Recycling Prize. Fourteen teams participated in Phase II of
            the Prize, which focused on building industry partnerships to
            design, simulate, and prototype a proof-of-concept solution.
          </p>
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            Phase II winners will advance to the final phase of the Prize, Phase
            III: Pilot Validation. Phase II Prototyping and Partnerships.
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    team name{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">entry title</th>
                  <th className="tds">location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Li Industries</td>
                  <td className="tds">Smart Battery Sorting System</td>
                  <td className="tds">Blacksburg, VA</td>
                </tr>
                <tr>
                  <td>OnTo Technology</td>
                  <td className="tds">DISC: Deactivate, Identify, Sort, Cut</td>
                  <td className="tds">Bend, OR</td>
                </tr>
                <tr>
                  <td>Powering the Future</td>
                  <td className="tds">Powering the Future</td>
                  <td className="tds">Glendale, WI</td>
                </tr>
                <tr>
                  <td>Renewance</td>
                  <td className="tds">Renewance Connect</td>
                  <td className="tds">Chicago, IL</td>
                </tr>
                <tr>
                  <td>Smartville</td>
                  <td className="tds">
                    Smartville Battery Reuse &amp; Recycling HUB System
                  </td>
                  <td className="tds">San Diego, CA</td>
                </tr>
                <tr>
                  <td>Team Portables</td>
                  <td className="tds">
                    Reward to Recycle – Closing the Loop on Portables
                  </td>
                  <td className="tds">Seattle, WA</td>
                </tr>
                <tr>
                  <td>Titan Advanced Energy Solutions</td>
                  <td className="tds">
                    Battago – Battery Market Intelligence Platform
                  </td>
                  <td className="tds">Sommerville, MA</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <StaticImage
              src="../../images/BRP-PhaseII-map.webp"
              className="img-styl"
              alt="brp phase 2"
              loading="lazy"
            />
          </div>
        </div>
        <div className="container">
          <h1 className="mac_h1">
            Phase I Concept Development and Incubation Winners
          </h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to the 15 winners who were selected based on their
            concepts to solve current challenges associated with collecting,
            sorting, storing, and transporting discarded lithium-ion batteries
            effectively and efficiently for recycling and recovery of key
            battery materials.
          </p>
          <p className="pcol-fz">
            Phase I winners will advance to Phase II Prototyping and
            Partnerships. In Phase II, the Phase I concepts will be further
            developed to moved towards the realization of their end-to-end
            solution.
          </p>
          <div>
            <table class="table is-hoverable is-fullwidth phaseI-table">
              <thead>
                <tr>
                  <th>
                    team name{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">entry title</th>
                  <th className="tds">location</th>
                  <th className="tds">track</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Admiral Instruments</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23260"
                      class="has-text-light"
                    >
                      Battery Sorting With Voltammetry &amp; Impedance Data
                    </a>
                  </td>
                  <td className="tds">Tempe, AZ</td>
                  <td className="tds">Separation and Sorting</td>
                </tr>
                <tr>
                  <td>EEDD</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23273"
                      class="has-text-light"
                    >
                      Battery Self Cooling for Safe Recycling
                    </a>
                  </td>
                  <td className="tds">Huntsville, AL</td>
                  <td className="tds">Safe Storage and Transportation</td>
                </tr>
                <tr>
                  <td>Holman Parts Distribution</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23271"
                      class="has-text-light"
                    >
                      Holman Parts Reverse Logistics Recycling Solution
                    </a>
                  </td>
                  <td className="tds">Pennsauken, NJ</td>
                  <td className="tds">
                    Collection<sup>*</sup>
                  </td>
                </tr>
                <tr>
                  <td>Li Industries</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23267"
                      class="has-text-light"
                    >
                      Smart Battery Sorting System
                    </a>
                  </td>
                  <td className="tds">Blacksburg, VA</td>
                  <td className="tds">Separation and Sorting</td>
                </tr>
                <tr>
                  <td>LIBIoT</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23239"
                      class="has-text-light"
                    >
                      Innovative Battery Collection System by Lithium-Ion
                      Battery Internet-of-Things (LIBIoT)
                    </a>
                  </td>
                  <td className="tds">Albany, NY</td>
                  <td className="tds">Separation and Sorting</td>
                </tr>
                <tr>
                  <td>OnTo Technology</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23289"
                      class="has-text-light"
                    >
                      Li-ion Identification
                    </a>
                  </td>
                  <td className="tds">Bend, OR</td>
                  <td className="tds">Separation and Sorting</td>
                </tr>
                <tr>
                  <td>Powering the Future</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/22995"
                      class="has-text-light"
                    >
                      Banking Today's Materials to Power Tomorrow
                    </a>
                  </td>
                  <td className="tds">Glendale, WI</td>
                  <td className="tds">Collection</td>
                </tr>
                <tr>
                  <td>Renewance</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23243"
                      class="has-text-light"
                    >
                      Reverse Logistics Marketplace
                    </a>
                  </td>
                  <td className="tds">Chicago, IL</td>
                  <td className="tds">
                    Reverse Logistics<sup>*</sup>
                  </td>
                </tr>
                <tr>
                  <td>Smartville</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23295"
                      class="has-text-light"
                    >
                      Distributed Battery Conditioning HUB
                    </a>
                  </td>
                  <td className="tds">San Diego, CA</td>
                  <td className="tds">Reverse Logistics</td>
                </tr>
                <tr>
                  <td>SNT Laser Focused</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23275"
                      class="has-text-light"
                    >
                      Utilizing Laser Cutting for Efficient Battery Pack
                      Dismantling
                    </a>
                  </td>
                  <td className="tds">Oklahoma City, OK</td>
                  <td className="tds">Other Ideas</td>
                </tr>
                <tr>
                  <td>Store Packs Umicore</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23264"
                      class="has-text-light"
                    >
                      Development of Four US Collection &amp; Storage Sites for
                      Lithium-Ion Automotive Battery Packs
                    </a>
                  </td>
                  <td className="tds">Raleigh, NC</td>
                  <td className="tds">Collection</td>
                </tr>
                <tr>
                  <td>Team EVBs</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23288"
                      class="has-text-light"
                    >
                      A Circular Economy for Electric Vehicle Batteries
                    </a>
                  </td>
                  <td className="tds">Seattle, WA</td>
                  <td className="tds">Other Ideas</td>
                </tr>
                <tr>
                  <td>Team Portables</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23287"
                      class="has-text-light"
                    >
                      Reward to Recycle – Closing the Loop on Portables
                    </a>
                  </td>
                  <td className="tds">Seattle, WA</td>
                  <td className="tds">Other Ideas</td>
                </tr>
                <tr>
                  <td>Team RRCO</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23246"
                      class="has-text-light"
                    >
                      Composite Discharge Media
                    </a>
                  </td>
                  <td className="tds">Madison, AL</td>
                  <td className="tds">Safe Storage and Transportation</td>
                </tr>
                <tr>
                  <td>Titan AES</td>
                  <td className="tds">
                    <a
                      href="https://www.herox.com/BatteryRecyclingPrize/round/460/entry/23240"
                      class="has-text-light"
                    >
                      IonView-Ultrasonic LIB Automated State of Health 1 second
                      test
                    </a>
                  </td>
                  <td className="tds">Somerville, MA</td>
                  <td className="tds">Separation and Sorting</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            * DOE reassigned the submissions to a different track per page 6 of
            the{" "}
            <a href="../../battery-recycling-prize-rules-and-scoring-criteria.pdf">
              Lithium-Ion Battery Recycling Prize Official Rules.
            </a>
          </p>
          <div class="has-text-centered">
            <a
              href="https://www.herox.com/BatteryRecyclingPrize/community"
              target="_blank"
            >
              <button type="button" class="btns">
                View the Full Winning submission on HeroX
              </button>
            </a>
          </div>
          <div>
            <StaticImage
              src="../../images/BRP-PhaseI-map.webp"
              className="img-styl"
              alt="brp phase I"
              loading="lazy"
            />
          </div>
        </div>
        <div className="container">
          <p className="pcol-fz">
            The intent of this Battery Recycling Prize is to:
          </p>
          <ul className="boxy-bullets gold-bullets ps list-styled">
            <li>
              Enable U.S.-based recyclers to reach economies of scale in their
              processes by providing higher volume feedstocks
            </li>
            <li>
              Attract private, public, state, and local dollar investments to
              scale collection, storage, and transportation of spent and
              discarded lithium-ion batteries
            </li>
            <li>
              Create new solutions and develop them from concepts to eventually
              recycle 90% of spent and discarded lithium-ion batteries
            </li>
            <li>
              Bring together diverse technologies and
              entities/innovators/businesses that when combined can provide a
              more comprehensive solution to the challenges facing the battery
              recycling supply chain.
            </li>
          </ul>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>

          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/BatteryRecyclingPrize">
              <button type="button" className="btns">
                Learn About this Prize
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Batterycycling
